/**
 * Anchor Link
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    $(".js-scroll").click(function() {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top - 20
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });

}(jQuery));