/**
 * Headroom
 *
 * @link http://wicky.nillia.ms/headroom.js/playroom/
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    $('.js-header').headroom({
        offset: 180,
        tolerance: 5,
        classes: {
            initial: 'animated',
            pinned: 'slideDown',
            unpinned: 'slideUp'
        }
    });

}(jQuery));