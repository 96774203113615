/**
 * Slick
 *
 * @link http://kenwheeler.github.io/slick/
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    $('.js-slide').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        adaptiveHeight: false,
        dots: false,
        fade: true
    });

}(jQuery));